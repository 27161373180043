.mouse {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: rgba($black, 1);
  border: 2px solid $black;
  border-radius: 2.5rem;
  box-sizing: border-box;
  pointer-events: none;
  transition: width 0.2s ease,
              height 0.2s ease,
              background-color 0.2s ease,
              border 0.2s ease;
  z-index: $mouse;

  &.is-hidden {
    display: none;
  }

  &.is-hover {
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba($white, 0.64);
  }

  &.is-negative {
    background-color: rgba($white, 1);
    border: 2px solid $white;

    &.is-hover {
      background-color: rgba($black, 0.64);
    }
  }
}
