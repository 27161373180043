.projectDetail {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $white;
  opacity: 0;
  pointer-events: none;

  &.is-negative {
    background-color: $black;

    .project-tag,
    .project-title,
    .project-subtitle,
    .project-credits,
    .carousel-counter {
      color: $white;
    }
  }

  &.is-open {
    opacity: 1;
    pointer-events: all;
  }

  &-project {
    top: 3.25rem;
    height: auto;
  }

  .project-info {
    position: relative;
  }

  &-scroll,
  &-scroll[data-scrollbar] {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .scroll-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-top: 40%;
    width: auto;
    height: 100%;
    box-sizing: border-box;
  }

  &-parallax,
  .carousel {
    flex-shrink: 0;
    display: block;
    position: relative;
    width: auto;
    overflow: hidden;

    & + .projectDetail-parallax,
    & + .carousel {
      margin-left: 12.5rem;
    }
  }

  &-parallaxImage {
    display: block;
    position: relative;
    width: auto;
    height: 100%;
    transform: scale(1.15);
    transform-origin: left center;
  }

  .is-a .projectDetail-parallax {
    height: 50%;

    &:nth-child(7n - 6),
    &:nth-child(7n - 3) {
      align-self: flex-end;
      margin-bottom: 1.25rem;
    }

    &:nth-child(7n - 5) {
      align-self: flex-start;
    }

    &:nth-child(7n - 4),
    &:nth-child(7n - 2),
    &:nth-child(7n) {
      align-self: center;
    }

    &:nth-child(7n - 1) {
      align-self: flex-start;
      margin-top: 1.25rem;
    }

    &:nth-child(7n) {
      height: 100%;
    }
  }

  .is-b .projectDetail-parallax {
    height: 50%;

    &:nth-child(7n - 6),
    &:nth-child(7n - 5),
    &:nth-child(7n - 4),
    &:nth-child(7n - 3),
    &:nth-child(7n - 1),
    &:nth-child(7n) {
      align-self: center;
    }

    &:nth-child(7n - 5) {
      height: 100%;
    }

    &:nth-child(7n - 4) {
      height: 75%;
    }

    &:nth-child(7n - 2) {
      align-self: flex-start;
    }

    &:nth-child(7n) {
      height: 100%;
    }
  }

  .is-b .carousel {
    align-self: center;
    height: calc(50% + 1.875rem);
    overflow: visible;

    & + .projectDetail-parallax {
      height: 75%;
    }
  }

  .is-c .carousel {
    align-self: center;
    height: calc(75% + 1.875rem);
    overflow: visible;
  }

  .is-c .projectDetail-parallax {
    height: 50%;

    &:nth-child(7n - 6),
    &:nth-child(7n - 1) {
      align-self: flex-end;
      height: 75%;
    }

    &:nth-child(7n - 5) {
      align-self: flex-start;
      height: 75%;
    }

    &:nth-child(7n - 4),
    &:nth-child(7n - 3),
    &:nth-child(7n) {
      align-self: center;
    }

    &:nth-child(7n - 4) {
      height: 100%;
    }

    &:nth-child(7n - 2) {
      align-self: flex-start;
      margin-top: 1.25rem;
    }
  }

  &-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  &-playerText {
    @include typo-rwd(play);
    position: absolute;
    top: 50%;
    left: 50%;
    color: $white;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
  }

  .scrollbar-track {
    height: 0.25rem;
    background: rgba($white, 0);
  }

  .scrollbar-thumb {
    background-color: $black;
    border-radius: 0;
  }

  @media (orientation: landscape) {
    &-project {
      top: 50%;
      transform: translateY(-50%);
    }

    .scroll-content {
      padding-top: 0;
    }
  }

  @media (min-width: 768px) {
    &-project {
      top: 4.5rem;
    }

    .scroll-content {
      padding-top: 24%;
    }

    &-parallax,
    .carousel {
      & + .projectDetail-parallax,
      & + .carousel {
        margin-left: 18.75rem;
      }
    }

    .is-b .carousel {
      height: calc(50% + 2.875rem);
    }
    .is-c .carousel {
      height: calc(75% + 2.875rem);
    }

    .is-a .projectDetail-parallax {
      &:nth-child(7n - 6),
      &:nth-child(7n - 3) {
        align-self: flex-end;
        margin-bottom: 2.5rem;
      }

      &:nth-child(7n - 1) {
        align-self: flex-start;
        margin-top: 2.5rem;
      }
    }

    .is-c .projectDetail-parallax:nth-child(7n - 2) {
      margin-top: 2.5rem;
    }
  }

  @media (min-width: 768px) and (orientation: landscape) {
    &-project {
      top: 50%;
    }

    .scroll-content {
      padding-top: 0;
    }
  }

  @media (min-width: 1024px) {
    &-project {
      top: 50%;
    }

    .scroll-content {
      padding-top: 0;
    }

    &-parallax,
    .carousel {
      & + .projectDetail-parallax,
      & + .carousel {
        margin-left: 25rem;
      }
    }

    .is-b .carousel {
      height: calc(50% + 3.25rem);
    }
    .is-c .carousel {
      height: calc(75% + 3.25rem);
    }

    .is-a .projectDetail-parallax {
      &:nth-child(7n - 6),
      &:nth-child(7n - 3) {
        align-self: flex-end;
        margin-bottom: 4.375rem;
      }

      &:nth-child(7n - 1) {
        align-self: flex-start;
        margin-top: 4.375rem;
      }
    }

    .is-c .projectDetail-parallax:nth-child(7n - 2) {
      margin-top: 4.375rem;
    }
  }
}
