.loader {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1.2s;

  &.is-open {
    opacity: 1;
    pointer-events: all;
  }
}
