.hero {
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  background-color: $black;

  &-wrapper {
    display: block;
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }

  &-titleWrapper {
    display: block;
    position: relative;

    & + .hero-subtitle {
      margin-top: 1.875rem;
    }
  }

  &-title {
    @include typo-rwd(mainTitle);

    & > div {
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      & > div + div {
        letter-spacing: -0.03rem;
      }
    }
  }

  &-titleMask {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0 0.4375rem;
  }

  &-subtitle {
    @include typo-rwd(mainSubtitle);
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    &-titleWrapper + .hero-subtitle {
      margin-top: 50px;
    }

    &-titleMask {
      margin: 0 0.625rem;
    }
  }

  @media (min-width: 1024px) {
    &-titleWrapper + .hero-subtitle {
      margin-top: 60px;
    }

    &-titleMask {
      margin: 0 1.5rem;
    }
  }

  @media (min-width: 1440px) {
    &-titleWrapper + .hero-subtitle {
      margin-top: 70px;
    }

    &-titleMask {
      margin: 0 1.25rem;
    }
  }
}
