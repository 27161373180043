.footer {
  @include typo-rwd(footer);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  padding: 2.5rem 0;
  text-align: center;
  box-sizing: border-box;

  a {
    display: inline-block;
    position: relative;

    & + a::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      height: 1px;
      background-color: $white;
    }
  }

  @media (min-width: 768px) {
    padding: 5rem 0;
  }
}
