.about,
.about[data-scrollbar] {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $black;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  &.is-open {
    opacity: 1;
    pointer-events: all;
  }

  &.is-vertical {
    .about-parallax {
      margin-left: 8.33%;
      width: 33.33%;
    }

    .about-parallaxImage {
      transform-origin: bottom left;
    }
  }

  .scroll-content {
    display: block;
    position: relative;
    width: 100%;
  }
}

.about {
  &-parallax {
    display: block;
    position: absolute;
    top: 2.5rem;
    width: calc(50% - 1rem);
    height: auto;
    overflow: hidden;
  }

  &-parallaxImage {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    transform: scale(1.1);
    transform-origin: top left;
  }

  &-info {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
    padding-top: 7rem;
    color: $white;
    box-sizing: border-box;

    & + .footer {
      margin-top: 7.5rem;
    }
  }

  &-infoDescription {
    @include typo-rwd(descripton);
    display: block;
    position: relative;

    & + .about-infoSection {
      margin-top: 3.5rem;
    }
  }

  &-infoSection {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;

    & + .about-infoSection {
      margin-top: 3rem;
    }
  }

  &-infoSectionTitle {
    @include typo-rwd(tagsTitle);
    display: block;
    position: relative;
    min-width: 25%;
    max-width: 25%;
    text-align: right;
    text-transform: uppercase;

    & + .about-infoSectionTags {
      margin-left: 1rem;
    }
  }

  &-infoSectionTags {
    @include typo-rwd(tags);
    flex: 1;

    a {
      display: inline-block;
      position: relative;
    }

    a::after {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      height: 1px;
      background-color: $white;
    }
  }

  .footer {
    padding-top: 0;
    color: $white;
  }

  @media (min-width: 768px) {
    &-parallax {
      width: 33.33%;
    }

    &-info {
      padding-top: 10.5rem;
    }

    &-infoDescription {
      & + .about-infoSection {
        margin-top: 5rem;
      }
    }

    &-infoSection {
      width: 66.66%;

      & + .about-infoSection {
        margin-top: 3.5rem;
      }
    }

    &-infoSectionTitle {
      min-width: 33.33%;
      max-width: 33.33%;
      text-align: left;

      & + .about-infoSectionTags {
        margin-left: 0;
      }
    }
  }

  @media (min-width: 1024px) {
    &-parallax {
      top: 5rem;
      width: calc(50% - 1.5rem);
    }

    &-info {
      padding-top: 17.5rem;

      & + .footer {
        margin-top: 15rem;
      }
    }

    &-infoDescription {
      & + .about-infoSection {
        margin-top: 8.75rem;
      }
    }

    &-infoSection {
      margin-right: 8.33%;

      & + .about-infoSection {
        margin-top: 7.5rem;
      }
    }
  }

  @media (min-width: 1440px) {
    &-info {
      padding-top: 22.5rem;
    }
  }
}
