.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: fixed;
  margin: 1.25rem 0;
  left: 50%;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  transform: translateX(-50%);
  z-index: $header;

  &.is-negative .header-button {
    color: $white;
    transition: color 1.2s 1.2s, opacity 1.2s;

    svg {
      pointer-events: none;
    }

    path {
      fill: $white;
      transition: fill 1.2s 1.2s;
    }
  }

  &.is-loaderOpen .header-button {
    opacity: 0;
    pointer-events: none;
  }

  &.is-aboutOpen .header-button {
    &:first-child {
      opacity: 1;
      pointer-events: all;
    }

    &:last-child {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.is-detailOpen .header-button {
    &:first-child,
    &:last-child {
      opacity: 1;
      pointer-events: all;
    }
  }

  &-button {
    @include typo-rwd(button);
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    color: $black;
    text-transform: uppercase;
    transition: color 1.2s, opacity 1.2s;

    svg {
      margin-right: 0.5rem;
      width: auto;
      height: 0.75rem;
    }

    path {
      fill: $black;
      transition: fill 1.2s;
    }

    &:first-child {
      opacity: 0;
      pointer-events: none;
    }

    &:last-child {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media (min-width: 768px) {
    margin: 1.5rem 0;

    &-button svg {
      margin-right: 0.75rem;
      height: 1rem;
    }
  }

  @media (min-width: 1440px) {
    margin: 2rem 0;

    &-button svg {
      margin-right: 1rem;
    }
  }
}
