// Colors
$black: #000000;
$white: #ffffff;

// Fonts
$font-base: 16;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 900;

// Index
$header: 10;
$mouse: 15;

// Layout
$layout-maxWidth: 2016px;

// Breakpoints
$grid-breakpoints: (
  xs: 0px,
  sm: 667px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1920px,
);
