.project,
.projectDetail-project {
  display: block;
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;

  &-info {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    text-align: center;
  }

  &-tag {
    @include typo-rwd(projectTag);
    display: block;
    position: relative;
    color: $black;
    text-transform: uppercase;

    & + .project-title {
      margin-top: 1.5rem;
    }
  }

  &-title {
    @include typo-rwd(projectTitle);
    width: auto;
    color: $black;

    * {
      pointer-events: none;
    }

    & + .project-subtitle {
      margin-top: 0.3125rem;
    }
  }

  &-subtitle {
    @include typo-rwd(projectSubtitle);
    color: $black;

    & + .project-credits {
      margin-top: 1.5rem;
    }
  }

  &-credits {
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    max-width: 20rem;
  }

  &-credit {
    @include typo-rwd(projectCredits);
    margin: 0 0.125rem;
    white-space: nowrap;

    &.is-details {
      white-space: initial;
    }

    span {
      font-family: 'neue-haas-grotesk-display', sans-serif;
      font-weight: $semibold;
      text-transform: uppercase;
    }
  }

  &-parallaxContainer {
    display: flex;
    align-items: flex-start;
    column-gap: 1rem;
    position: relative;
    width: 100%;
    height: 50vh;
    pointer-events: none;

    &.is-type01,
    &.is-type03,
    &.is-type05 {
      justify-content: flex-start;

      .project-parallax {
        margin-left: -1rem;
        width: calc(75% - 0.5rem);
        max-width: 20rem;
      }
    }

    &.is-type02,
    &.is-type04 {
      justify-content: flex-end;

      .project-parallax {
        width: calc(75% - 0.5rem);
        max-width: 20rem;
      }
    }

    &.is-type05 {
      justify-content: center;
    }
  }

  &-parallax {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    max-height: 100vh;
    overflow: hidden;
  }

  &-parallaxImage {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    transform: scale(1.15);
    transform-origin: top center;
  }

  @media (orientation: landscape) {
    min-height: 41.6875rem;
  }

  @media (min-width: 768px) {
    &-tag + .project-title {
      margin-top: 4.375rem;
    }

    &-title + .project-subtitle {
      margin-top: 0.625rem;
    }

    &-subtitle + .project-credits {
      margin-top: 2rem;
    }

    &-credits {
      max-width: 35rem;
    }

    &-parallaxContainer {
      &.is-type01 .project-parallax,
      &.is-type03 .project-parallax {
        margin-left: -2rem;
        width: calc(62.5% - 1rem);
        max-width: 32.5rem;
      }

      &.is-type02 .project-parallax,
      &.is-type04 .project-parallax,
      &.is-type05 .project-parallax {
        width: calc(50% - 0.5rem);
        max-width: 32.5rem;
      }

      &.is-type04 .project-parallax {
        margin-right: calc(12.5% - 2rem);
      }
    }
  }

  @media (min-width: 1024px) {
    &-tag + .project-title,
    &-subtitle + .project-credits {
      margin-top: 5.3125rem;
    }

    &-credits {
      max-width: 40rem;
    }

    &-credit {
      margin: 0 0.25rem;
    }

    &-parallaxContainer {
      &.is-type01 .project-parallax {
        margin-left: -3rem;
        width: 41.66%;
        max-width: 38.75rem;
      }

      &.is-type02,
      &.is-type04 {
        justify-content: center;

        .project-parallax {
          margin-left: calc(8.33% + 1.5rem);
          width: calc(41.66% - 1.5rem);
          max-width: 38.75rem;
        }
      }

      &.is-type03 {
        justify-content: flex-end;

        .project-parallax {
          margin-right: -3rem;
          width: calc(41.66% + 1.5rem);
          max-width: 38.75rem;
        }
      }

      &.is-type04 {
        justify-content: flex-start;

        .project-parallax {
          margin-left: calc(8.33% - 3rem);
        }
      }

      &.is-type05 .project-parallax {
        width: 33.33%;
        max-width: 38.75rem;
      }
    }
  }

  @media (min-width: 1200px) {
    & + .project {
      margin-top: 200px;
    }
  }

  @media (min-width: 1440px) {
    & + .project {
      margin-top: 400px;
    }

    &-tag + .project-title,
    &-subtitle + .project-credits {
      margin-top: 6.25rem;
    }

    &-credits {
      max-width: 50rem;
    }

    &-credit {
      margin: 0.125rem 0.375rem;
    }
  }
}
