.projectsContainer {
  position: relative;
  width: 100%;
  text-align: center;
  overflow: hidden;

  &-projects {
    display: block;
    position: relative;
    margin: 0 auto;
  }
}
