@import url("https://p.typekit.net/p.css?s=1&k=ops1zyx&ht=tk&f=39496.39498&a=34557635&app=typekit&e=css");
@font-face {
  font-family: 'neue-haas-grotesk-display';
  src: url("https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/d?subset_id=2&fvd=n3&v=3") format("woff"),
       url("https://use.typekit.net/af/afb65e/00000000000000003b9b2044/27/l?subset_id=2&fvd=n3&v=3") format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: 'neue-haas-grotesk-display';
  src: url("https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),
       url("https://use.typekit.net/af/28f000/00000000000000003b9b2048/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: 'neue-haas-grotesk-display';
  src: url("https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),
       url("https://use.typekit.net/af/8a200c/00000000000000003b9b204a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: 'Slack Light';
  src: url('../../fonts/Slack-Light.woff') format('woff'),
       url('../../fonts/Slack-Light.woff2') format('woff2');
}

$neue-haas: 'neue-haas-grotesk-display', sans-serif;
$slack-light: 'Slack Light', Times, serif;

$font-families: (
  neue-haas: $neue-haas,
  slack-light: $slack-light,
);

$typography: (
  body: (
    format: (
      xs: (
        ff: neue-haas,
        fz: 1rem,
        lh: normal,
      ),
    )
  ),
  mainTitle: (
    format: (
      xs: (
        ff: slack-light,
        fz: 4rem,
        fw: $light,
        lh: 122%,
        ls: -0.04em
      ),
      md: (
        fz: 6rem,
        lh: 120%,
      ),
      lg: (
        fz: 7.5rem,
      ),
      xl: (
        fz: 9rem,
      )
    )
  ),
  mainSubtitle: (
    format: (
      xs: (
        ff: neue-haas,
        fz: 0.75rem,
        fw: $medium,
        lh: 120%,
      ),
      md: (
        fz: 1.5rem,
      ),
      // lg: (
      //   fz: 1.75rem,
      // ),
      // xl: (
      //   fz: 2.25rem,
      // ),
    )
  ),
  projectTag: (
    format: (
      xs: (
        ff: neue-haas,
        fz: 0.625rem,
        fw: $semibold,
        lh: 0.75rem,
      ),
      md: (
        fz: 0.875rem,
        lh: 1rem,
      ),
      lg: (
        fz: 1.25rem,
        lh: 2.25rem,
      ),
      xl: (
        fz: 1.75rem,
        lh: 3rem,
      ),
    )
  ),
  projectTitle: (
    format: (
      xs: (
        ff: slack-light,
        fz: 2.25rem,
        fw: $light,
        lh: 2.75rem,
        ls: -0.02em,
      ),
      md: (
        fz: 4rem,
        lh: 4.75rem,
      ),
      lg: (
        fz: 4.5rem,
        lh: 5.75rem,
      ),
      xl: (
        fz: 7.5rem,
        lh: 9rem,
      ),
    )
  ),
  projectSubtitle: (
    format: (
      xs: (
        ff: neue-haas,
        fz: 1.75rem,
        fw: $light,
        lh: 2.125rem,
        ls: 0.025em,
      ),
      md: (
        fz: 3.75rem,
        lh: 4.5rem,
      ),
      lg: (
        fz: 4.5rem,
        lh: 5.75rem,
      ),
      xl: (
        fz: 7.5rem,
        lh: 9rem,
      ),
    )
  ),
  projectCredits: (
    format: (
      xs: (
        ff: slack-light,
        fz: 0.5625rem,
        fw: $medium,
        lh: 1rem,
      ),
      md: (
        ff: slack-light,
        fz: 0.875rem,
        fw: $medium,
        lh: 1.75rem,
      ),
      lg: (
        ff: slack-light,
        fz: 1.125rem,
        fw: $medium,
        lh: 1.75rem,
      ),
      xl: (
        ff: slack-light,
        fz: 1.375rem,
        fw: $medium,
        lh: 1.75rem,
      ),
    )
  ),
  footer: (
    format: (
      xs: (
        ff: slack-light,
        fz: 1.125rem,
        fw: $light,
        lh: 150%,
      ),
      md: (
        fz: 1.5rem,
      ),
      lg: (
        fz: 2rem,
      ),
      xl: (
        fz: 3rem,
      ),
    )
  ),
  button: (
    format: (
      xs: (
        ff: neue-haas,
        fz: 0.75rem,
        fw: $medium,
        lh: 120%,
      ),
      md: (
        fz: 1rem,
      ),
      lg: (
        fz: 1.125rem,
      ),
      xl: (
        fz: 1.25rem,
      ),
    )
  ),
  descripton: (
    format: (
      xs: (
        ff: slack-light,
        fz: 0.875rem,
        fw: $light,
        lh: 150%,
      ),
      md: (
        fz: 1.5rem,
      ),
      lg: (
        fz: 2rem,
      ),
      xl: (
        fz: 3rem,
      ),
    )
  ),
  tagsTitle: (
    format: (
      xs: (
        ff: neue-haas,
        fz: 0.75rem,
        fw: $bold,
        lh: 150%,
      ),
      md: (
        fz: 1rem,
      ),
      lg: (
        fz: 1.25rem,
      ),
      xl: (
        fz: 1.5rem,
      ),
    )
  ),
  tags: (
    format: (
      xs: (
        ff: slack-light,
        fz: 0.75rem,
        fw: $light,
        lh: 132%,
      ),
      md: (
        fz: 1.125rem,
      ),
      lg: (
        fz: 1.75rem,
      ),
      xl: (
        fz: 2.25rem,
      ),
    )
  ),
  play: (
    format: (
      xs: (
        ff: slack-light,
        fz: 1.5rem,
        fw: $light,
        lh: 150%,
      ),
      md: (
        fz: 3rem,
      ),
      lg: (
        fz: 4rem,
      ),
    )
  ),
  counter: (
    format: (
      xs: (
        ff: neue-haas,
        fz: 0.875rem,
        fw: $semibold,
        lh: 1.125rem,
      ),
      lg: (
        fz: 1.125rem,
        lh: 1.375rem
      )
    )
  )
);
