// Global
* {
  box-sizing: border-box;
}

html {
  font-size: $font-base;

  * {
    cursor: none;
  }
  video {
    cursor: auto;
  }
}

body {
  @include typo-rwd(body);
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: $black;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;

  .scrollbar-track {
    width: 0.25rem;
    background: rgba($white, 0);
  }

  .scrollbar-thumb {
    background-color: $black;
    border-radius: 0;
  }
}

#root,
.App {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

// Content
iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol,
dl,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:focus {
    outline: none;
  }
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
}

.mainWrapper {
  padding: 0 1rem;
  max-width: $layout-maxWidth;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .mainWrapper {
    padding: 0 2rem;
  }
}

@media (min-width: 1280px) {
  .mainWrapper {
    padding: 0 2.5rem;
  }
}

@media (min-width: 1440px) {
  .mainWrapper {
    padding: 0 3rem;
  }
}
