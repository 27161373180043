.carousel {
  display: inline-block;
  position: relative;

  &-parallax {
    flex-shrink: 0;
    display: block;
    position: relative;
    width: auto;
    height: 100%;
    overflow: hidden;
  }

  &-parallaxImage {
    display: block;
    position: relative;
    width: auto;
    height: 100%;
    transform: scale(1.15);
    transform-origin: left center;
    transition: opacity 1s;
    opacity: 0;

    &.is-active {
      opacity: 1;
    }

    & ~ .carousel-parallaxImage {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-counter {
    @include typo-rwd(counter);
    display: block;
    position: relative;
    margin-top: 0.75rem;
    text-align: right;
  }

  @media (min-width: 768px) {
    &-counter {
      margin-top: 1.5rem;
    }
  }

  @media (min-width: 1024px) {
    &-counter {
      margin-top: 1.875rem;
    }
  }
}
